import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const TwoColumn = styled.div<{
  dominant?: "left" | "right";
  reverse?: boolean;
}>`
  //Breakpoint - XLarge
  @media (min-width: 1441px) {
    display: grid;
    grid-gap: 6rem;
    grid-template-columns: 7fr 5fr;

    ${({ dominant }) =>
      dominant === "right" &&
      css`
        grid-template-columns: 5fr 7fr;
      `}
  }

  //Breakpoint - Large
  @media (max-width: 1440px) {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 7fr 5fr;

    ${({ dominant }) =>
      dominant === "right" &&
      css`
        grid-template-columns: 5fr 7fr;
      `}
  }

  //Breakpoint - Medium
  @media (max-width: 1024px) {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 6fr 6fr;

    ${({ dominant }) =>
      dominant === "right" &&
      css`
        grid-template-columns: 6fr 6fr;
      `}
  }

  //Breakpoint - Small
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    ${({ reverse }) =>
      reverse &&
      css`
        flex-direction: column-reverse;
      `}
  }
`;
