import { FallbackProps } from "react-error-boundary";
import { TwoColumn } from "components/common/ui/TwoColumn";
import { AppLayout } from "components/common/layouts/AppLayout";
import { PageWrapper } from "components/common/PageWrapper";
import { H1, P } from "components/common/Typography/typography";
import { SupportOptions } from "components/common/SupportOptions";
import { Box } from "@mui/system";
import { debug } from "tools";
import { FormattedMessage } from "services/translation";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect, useRef } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { usePathname } from "next/navigation";

export function ErrorFallback(props: FallbackProps) {
  const error = props.error as unknown as any;
  useEffect(() => {
    // Log the error to console and datadog
    debug(error);
    datadogRum.addError(error);
  }, []);

  //const router = useRouter();
  const pathname = usePathname();
  const errorLocation = useRef(pathname);
  useEffect(() => {
    if (pathname !== errorLocation.current) {
      props.resetErrorBoundary();
    }
  }, [pathname]);

  return (
    <AppLayout>
      <PageWrapper>
        <TwoColumn>
          <div>
            <H1 mb="8px">
              <Box mr="8px" display="inline-block">
                <ErrorIcon color="error" />
              </Box>
              <FormattedMessage id="errorBoundar.title" />
            </H1>
            <P>
              <FormattedMessage id="errorBoundary.description" />
            </P>
          </div>
        </TwoColumn>
        <Box mt="36px">
          <SupportOptions />
        </Box>
      </PageWrapper>
    </AppLayout>
  );
}
