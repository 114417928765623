import { MouseEvent, ReactNode } from "react";
import { Box } from "@mui/system";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { Grid, Typography } from "@mui/material";
import { hsaPhoneNumber, hsaSupportEmail } from "constant/contact";
import { useIntl } from "services";

interface SupportOptionProps {
  name: string;
  icon: ReactNode;
  cta: ReactNode;
}

const SupportOption: React.FC<SupportOptionProps> = ({ icon, name, cta }) => {
  return (
    <Grid item xs={12} sm={12} md={4}>
      <Box
        border="1px solid #E5E5E5"
        borderRadius="8px"
        padding="24px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          sx={{
            svg: {
              width: "40px",
              height: "40px",
            },
          }}
        >
          {icon}
        </Box>
        <Typography variant="xlHeading" my="12px" component="h3">
          {name}
        </Typography>
        {cta}
      </Box>
    </Grid>
  );
};

export function SupportOptions() {
  const { formatMessage } = useIntl();

  const handleOpenChat = (e: MouseEvent) => {
    e.preventDefault();
    if (window.icPatronChat) {
      window.icPatronChat.show();
    }
  };

  return (
    <Grid container spacing={3}>
      <SupportOption
        name={formatMessage({ id: "supportPage.callTitle" })}
        icon={<PhoneInTalkOutlinedIcon />}
        cta={<a href={`tel:${hsaPhoneNumber}`}>{hsaPhoneNumber}</a>}
      ></SupportOption>
      <SupportOption
        name={formatMessage({ id: "supportPage.emailTitle" })}
        icon={<MailOutlineOutlinedIcon />}
        cta={<a href={`mailto:${hsaSupportEmail}`}>{hsaSupportEmail}</a>}
      ></SupportOption>
      <SupportOption
        name={formatMessage({ id: "supportPage.chatTitle" })}
        icon={<QuestionAnswerOutlinedIcon />}
        cta={
          <a href="" onClick={handleOpenChat}>
            {formatMessage({ id: "supportPage.openChatCta" })}
          </a>
        }
      ></SupportOption>
    </Grid>
  );
}
